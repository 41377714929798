import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import Heading from "../../components/Heading";
import Text from "../../components/Text";

import styles from "./AuditCard.module.scss";

export interface AuditCardProps {
  id?: number;
  title: string;
  info: string | string[];
  src: string;
  delay: string;
}

const AuditCard = ({ title, info, src, delay }: AuditCardProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    const element = elementRef.current;
    if (element) {
      const elementTop = element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (elementTop > windowHeight || windowHeight > elementTop + 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderInfo = Array.isArray(info) ? (
    info.map((el) => (
      <Text className={styles.wrapper__info_block__text_item}>✔ {el}</Text>
    ))
  ) : (
    <Text className={styles.wrapper__info_block__text_item}>{info}</Text>
  );

  return (
    <div ref={elementRef} className={styles.wrapper}>
      <div
        style={{ animationDelay: delay }}
        className={classNames(styles.wrapper__fade_in, {
          [styles.wrapper__visible]: isVisible,
        })}>
        <img className={styles.wrapper__img} src={src} alt={title} />
        <div className={styles.wrapper__content}>
          <div className={styles.wrapper__info_block}>
            <Heading className={styles.wrapper__info_block__title} isTitle>
              {title}
            </Heading>
            <div className={styles.wrapper__info_block__text}>{renderInfo}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditCard;
