import { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import routes from "./config";
import { Styles } from "../pages/styles";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={`page ${routeItem.id}`}
              path={routeItem.path}
              exact={routeItem.exact}
              component={() => <routeItem.component />}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default Router;
