import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import usePortal from "react-useportal";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../components/Button";
import { LogoIcon } from "../../icons/index";
import { menuItems } from "../../utils/index";
import { changeIndex } from "../../slices/mainSlice";
import { FullPageContext } from "../../context/index";
import { activeIndexSelector } from "../../slices/mainSlice";

import styles from "./Header.module.scss";
import MobileMenu from "./MobileMenu";

const Header = ({ isOnePage }) => {
  const [isScroll, setIsScroll] = useState(false);
  const { Portal } = usePortal();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;

  const context = useContext(FullPageContext);
  const activeIndex = useSelector(activeIndexSelector);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > 100) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const moveToSectionHandler = (index) => {
    history.push("/");
    if (context?.moveToSection && pathname === "/") {
      context?.moveToSection?.moveTo(index);
    } else {
      dispatch(changeIndex(index));
    }
  };

  useEffect(() => {
    if (activeIndex && context?.moveToSection?.moveTo && pathname === "/") {
      context?.moveToSection?.moveTo(activeIndex);
    }
  }, [activeIndex, pathname, context?.moveToSection]);

  const setInitialIndex = () => {
    isOnePage ? moveToSectionHandler(1) : history.push("/");
    dispatch(changeIndex(1));
  };

  const renderNavbarItems = menuItems.map((el) => (
    <Button
      key={`menuItems${el.id}`}
      className={classNames(styles.container__navbar__item, {
        [styles.container__navbar__item_two]: activeIndex === 2,
      })}
      onClick={() => moveToSectionHandler(el.section)}>
      {el.name}
    </Button>
  ));

  return (
    <Portal>
      <header
        className={classNames(styles.wrapper, {
          [styles.wrapper__small]: !isOnePage && isScroll,
        })}>
        {/* Added <h1> for SEO optimization */}
        <h1 className={styles.global_title}>O&M</h1>
        <div className={styles.container}>
          <div
            role='button'
            onClick={setInitialIndex}
            className={classNames(styles.logo, {
              [styles.logo__small]: !isOnePage && isScroll,
            })}>
            <LogoIcon />
          </div>
          <nav className={styles.container__navbar}>{renderNavbarItems}</nav>
          <MobileMenu />
        </div>
      </header>
    </Portal>
  );
};

Header.propTypes = {
  isOnePage: PropTypes.bool,
};

Header.defaultProps = {
  Header: false,
};

export default Header;
