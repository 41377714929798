import Footer from "../../components/Footer";

import styles from "./Footer.module.scss";

const FooterContainer = () => {
  return (
    <section className="section">
      <div className={`container ${styles.wrapper}`}>
        <Footer />
      </div>
    </section>
  );
};

export default FooterContainer;
