import classNames from "classnames";
import { useSelector } from "react-redux";

import Text from "../../../components/Text";
import Heading from "../../../components/Heading";
import { activeIndexSelector } from "../../../slices/mainSlice";

import styles from "./Main.module.scss";
import Video from "../../../components/Video";

const Main = () => {
  const activeIndex = useSelector(activeIndexSelector);

  return (
    <section className={`${styles.height_response} section`}>
      <Video src='/img/videos/about.mp4'  poster='/img/images/poster.png' className={styles.video_container}/>
      <div className={styles.wrapper}>
        <div className={styles.wrapper__content}>
          <div className={styles.wrapper__content__left_path}>
            <Heading className={styles.wrapper__content__text}>
              What Is
              <span
                className={classNames(styles.wrapper__content__title, {
                  [styles.wrapper__content__title_anima]: activeIndex === 1,
                })}>
                Operations and Maintenance?
              </span>
            </Heading>
            {/* <Text
              isSubtitle
              className={classNames(styles.wrapper__content__subtitle, {
                [styles.wrapper__content__subtitle_anima]: activeIndex === 1,
              })}>
              As a form of preventive maintenance, O&M can include any task
              meant to upkeep equipment before an issue arises
            </Text> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
