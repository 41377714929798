const Fb = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
  >
    <path
      d="M15.9982 32.2398C24.8337 32.3743 32.1053 25.3207 32.2398 16.4852C32.3743 7.64965 25.3207 0.378006 16.4852 0.243516C7.64968 0.109026 0.378035 7.16261 0.243545 15.9981C0.109055 24.8337 7.16264 32.1053 15.9982 32.2398Z"
      fill="url(#fb0_linear)"
    />
    <path
      d="M20.2569 16.928L17.4022 16.8845L17.243 27.3427L12.918 27.2769L13.0772 16.8187L11.0201 16.7874L11.0761 13.1119L13.1331 13.1433L13.1693 10.7648C13.1952 9.06401 14.0436 6.41299 17.5993 6.46712L20.8028 6.52928L20.7485 10.0969L18.424 10.0615C18.0427 10.0557 17.5037 10.2381 17.4913 11.0494L17.4584 13.2125L20.6907 13.2617L20.2569 16.928Z"
      fill="#F1F2F2"
    />
    <defs>
      <linearGradient
        id="fb0_linear"
        x1="16.4852"
        y1="0.243516"
        x2="15.9982"
        y2="32.2398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3B76F3" />
        <stop offset="1" stopColor="#2B57B2" />
      </linearGradient>
    </defs>
  </svg>
);

export default Fb;
