import { Row, Col } from "antd";
import { Slide } from "react-awesome-reveal";
import { withTranslation } from "react-i18next";
import { MiddleBlockSection, Content, ContentWrapper } from "./styles";

interface MiddleBlockProps {
  title: string;
  content: string;
  button?: string;
  t: any;
}

const MiddleBlock = ({ title, content, t }: MiddleBlockProps) => {
  return (
    <MiddleBlockSection>
      <Slide direction='up'>
        <Row justify='center' align='middle'>
          <ContentWrapper>
            <Col lg={24} md={24} sm={24} xs={24}>
              <h6>{t(title)}</h6>
              <Content>{t(content)}</Content>
            </Col>
          </ContentWrapper>
        </Row>
      </Slide>
    </MiddleBlockSection>
  );
};

export default withTranslation()(MiddleBlock);
