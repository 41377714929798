const Telegram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
  >
    <path
      d="M16.4484 32.2398C25.2839 32.3743 32.5555 25.3207 32.69 16.4852C32.8245 7.64965 25.7709 0.378006 16.9354 0.243516C8.09987 0.109026 0.82823 7.16261 0.69374 15.9981C0.55925 24.8337 7.61284 32.1053 16.4484 32.2398Z"
      fill="url(#telegram0_linear)"
    />
    <path
      d="M8.01951 15.764L23.5349 10.0515C24.2548 9.80373 24.8734 10.2465 24.625 11.3255L24.6263 11.3242L21.8116 23.6575C21.6036 24.5318 21.0791 24.7371 20.3562 24.3127L16.4015 21.3042L14.4441 23.1333C14.2275 23.3434 14.0448 23.5206 13.6315 23.5143L13.9775 19.4484L21.4919 12.8647C21.8188 12.5856 21.4266 12.4197 20.997 12.6958L11.749 18.3237L7.81885 17.0318C6.96576 16.7467 6.956 16.1612 8.01951 15.764Z"
      fill="#F1F2F2"
    />
    <defs>
      <linearGradient
        id="telegram0_linear"
        x1="25.7806"
        y1="32.3819"
        x2="14.269"
        y2="0.202929"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#039BE5" />
        <stop offset="1" stopColor="#35BDFF" />
      </linearGradient>
    </defs>
  </svg>
);

export default Telegram;
