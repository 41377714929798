import styled from "styled-components";
import { Link } from "react-router-dom";

export const WrapperContent = styled("div")`
display: flex;
align-items: center;
justify-content: space-between;
max-width: calc(100vw - 100px);
width: 100%;
margin-bottom: 14px;

@media screen and (max-width: 992px) {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    max-width: 100%;
  }

  @media screen and (max-width: 630px) {
    flex-direction: column;
  }
`;

export const FooterSection = styled("footer")`
  background: rgb(241, 242, 243);
  padding: 2.5rem 0;
`;

export const Title = styled("h4")`
  font-size: 22px;
  text-transform: capitalize;
  color: #fcc52a;

  @media screen and (max-width: 414px) {
    padding: 8px 0 !important;
  }
`;

export const NavLink = styled("a")`
  padding: 0 12px;
  transition: all 0.5s;

  &:hover {
    transform: scale(1.1);
    transition: all 0.3s;
  }
`;

export const Extra = styled("footer")`
  background: rgb(241, 242, 243);
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

export const LogoContainer = styled("div")`
  display: flex;
  position: relative;

  svg {
    width: 70px;
    height: auto;
  }
`;

export const Para = styled("div")`
  color: #606060;
  font-size: 14px;
  width: 100%;
`;

export const Large = styled("p") <any>`
  font-size: 16px;
  padding-top: 12px;
  color: #000;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  line-height: 24px;
  display: block;
  margin-bottom: 0.625rem;
  transition: all 0.3s ease-in-out;
  max-width: max-content;

  &:hover {
    color: rgb(252, 197, 42);
    text-underline-position: under;
    text-decoration: rgb(252, 197, 42) wavy underline;
  }
`;

export const Chat = styled("p")`
  color: rgb(24, 33, 109);
  max-width: fit-content;
  border-bottom: 1px solid rgb(24, 33, 109);
  cursor: pointer;
  margin-top: 1rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-bottom: 1px solid rgb(252, 197, 42);
    color: #000;
  }
`;

export const Empty = styled("div")`
  position: relative;
  height: 5vh;

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const WebBottomContent = styled("div")`
display:flex;
width: 100%;

div{
  width: 100%;

  div{
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
    display: none;
  }
`;

export const SocText = styled("div")`
  font-size: 20px;
  margin-left: 8px;
`;

export const FooterContainer = styled("div")`
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.1s ease-in-out;
  margin: 12px 0;

  a {
    // width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  svg {
    &:hover,
    &:active,
    &:focus {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
`;

export const Language = styled("h4")`
  font-size: 22px;
  text-transform: capitalize;
  color: #fcc52a;

  @media screen and (max-width: 414px) {
    padding: 4px 0;
    font-size: 20px;
  }
`;

export const Label = styled("label")`
  font-size: 22px;
  text-transform: capitalize;
  color: #fcc52a;
  display: block;
  margin-bottom: 2rem;
  font-family: "Motiva Sans Bold", serif;

  @media screen and (max-width: 414px) {
    padding: 8px 0 !important;
  }
`;

export const LanguageSwitch = styled("div") <any>`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const LanguageSwitchContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 85px;
`;

export const MapContainer = styled("div")`
  width: 65%;
  height: calc(100vh - 286px);
  border: 2px solid #fcc52a;
  box-shadow: 0 3px 10px #606060;

  @media screen and (max-width: 992px) {
    width: 100%;
    height: 35vh;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 50vh;

    ymaps: first-child {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 630px) {
    width: 100%;
    height: 30vh;
  }
`;

export const RightBlock = styled('div')`
 display: flex;
 flex-direction: column;
 justify-content: center;
 max-width:35%
 width: 100%;
 margin-right: 16px;

 @media screen and (max-width: 992px) {
    justify-content: space-between;
    max-width: 100%;
    margin-right: 0;
    gap: 16px;
    width: 100%;
    flex-direction: row;

    div{
        max-width: 100%;
        width:100%;
        
        /* div{
          width: 30vw;
        } */
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  @media screen and (max-width: 630px) {
    flex-direction: row;
flex-wrap: wrap;
padding-bottom: 24px;
 
  }
`;

export const MobileAddress = styled("div") <any>`
display: none;

@media screen and (max-width: 992px) {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  justify-content: flex-start;
  max-width: 100%;
  width: 100%;

  &>*{
    max-width: 100%;
    width: 100%;
  }
  }
`;

export const FooterTopContainer = styled("div") <any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 100vw;
  padding-top: 12px;
  border-top: ${(p) => (p.border ? "1px solid #CDD1D4" : "")};


  @media screen and (max-width: 992px) {
    border-top: none;
    padding-top: 0;
  }

  @media screen and (max-width: 992px) {
    justify-content: center;
  } 
`;