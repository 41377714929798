import styled from "styled-components";

export const StyledContainer = styled("div") <any>`
  position: relative;
  width: 100%;
  max-width: 100vw;
  padding: 80px 0 0;
  margin-right: auto;
  margin-left: auto;
  border-top: ${(p) => (p.border ? "1px solid #CDD1D4" : "")};

  @media screen and (max-width: 1024px) {
    padding: 90px 0 0;
  }

  @media screen and (max-width: 768px) {
    padding: 90px 0 0;
  }
`;
