import { useState, useContext } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Close from "../../../icons/menu/Close";
import { menuItems } from "../../../utils";
import { changeIndex } from "../../../slices/mainSlice";
import { FullPageContext } from "../../../context";

import styles from "./MobileMenu.module.scss";

const MobileMenu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = history.location;

  const context = useContext(FullPageContext);
  const [isOpen, setIsOpen] = useState(false);

  const handlerClick = () => setIsOpen(!isOpen);

  const moveToSectionHandler = (index) => {
    history.push("/");
    if (context?.moveToSection && pathname === "/") {
      context?.moveToSection?.moveTo(index);
    } else {
      dispatch(changeIndex(index));
    }
    handlerClick();
  };

  const renderMenuItems = menuItems.map((el) => (
    <div
      role='button'
      onClick={() => moveToSectionHandler(el.section)}
      key={el.name}
      className={styles.wrapper__menu__list__item}>
      {el.name}
    </div>
  ));

  return (
    <nav className={styles.wrapper}>
      <div
        className={classNames(styles.wrapper__menu, {
          [styles.wrapper__menu_close]: !isOpen,
        })}>
        <div className={styles.wrapper__menu__icon}>
          <Close onClick={handlerClick} />
        </div>
        <div className={styles.wrapper__menu__list}>{renderMenuItems}</div>
      </div>
      <div className={styles.wrapper__burger} onClick={handlerClick}>
        <div
          className={classNames(styles.wrapper__burger__inner, {
            [styles.wrapper__burger__inner_active]: isOpen,
          })}>
          <span className={styles.wrapper__burger__line_one}></span>
          <span
            className={classNames({
              [styles.wrapper__burger__line_two]: isOpen,
            })}></span>
          <span
            className={classNames({
              [styles.wrapper__burger__line_three]: isOpen,
            })}></span>
        </div>
      </div>
    </nav>
  );
};

export default MobileMenu;
