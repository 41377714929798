import ReactFullpage from "@fullpage/react-fullpage";
import { useDispatch } from "react-redux";

import Main from "../Main";
import Goals from "../Goals";
import AboutUs from "../AboutUs";
import OurWorks from "../OurWorks";

import Partners from "../Partners";
import OurTeam from "../OurTeam";

import FullPageLayout from "../../../layouts/FullPage";
import { changeIndex } from "../../../slices/mainSlice";

const Fullpage = () => {
  const dispatch = useDispatch();

  const changeActiveStep = (section, destination) => {
    dispatch(changeIndex(destination.index + 1));
  };

  const renderFullPageContent = ({ fullpageApi }) => {
    return (
      <ReactFullpage.Wrapper>
        <FullPageLayout fullpageApi={fullpageApi}>
          <Main />
          <AboutUs />
          <Goals />
          <OurWorks />
          <Partners />
          <OurTeam />
        </FullPageLayout>
      </ReactFullpage.Wrapper>
    );
  };

  const renderFullPage = {
    navigation: false,
    fitToSection: false,
    scrollingSpeed: 1000,
    keyboardScrolling: false,
    scrollHorizontally: false,
    onLeave: changeActiveStep,
    render: renderFullPageContent,
  };

  return <ReactFullpage {...renderFullPage} />;
};

export default Fullpage;
