const menuItems = [
    {
      id: 1,
      section: 1,
      name: "Home",
    },
    {
      id: 2,
      section: 2,
      name: "About Us",
    },
    {
      id: 3,
      section: 4,
      name: "Our work",
    },
    {
      id: 4,
      section: 5,
      name: "Partners",
    },
    {
      id: 5,
      section: 7,
      name: "Contact",
    },
  ];

  export default menuItems;