const auditCards = [
    {
      id: 1,
      title: "What the energy audit give you",
      src: "https://www.taxrobo.in/assets/images/audit/top-banner10.png",
      info: [
        "Energy saving measures",
        "A brief summary of the installed energy-using systems",
        "Energy model",
        "Energy efficiency measures",
        "Consumption portfolio",
        "Improving lighting",
        "Thermal imaging",
        "Building envelope",
        "HVAC (heating, ventilation, and air conditioning)",
        "Plug load",
      ],
      delay: "0.6s",
    },
    {
      id: 2,
      title: "What the energy audit give you",
      src:
        "https://cdni.iconscout.com/illustration/premium/thumb/accounting-and-audit-3940532-3305246.png?f=webp",
      info: [
        "Building Total Energy and Utility Billing Study Report",
        "Introduction of energy efficiency and energy-saving measures for building lighting systems",
        "Study of heating, cooling, and ventilation systems of the building and proposal of appropriate energy-saving measures",
        "Advice on the implementation of various energy-saving passive and active systems in the building",
        "Study of the building envelope, infrared imaging, and proposal of appropriate energy-saving measures",
        "Study of the substation, boiler room, and other systems",
      ],
      delay: "0.4s",
    },
    {
      id: 3,
      title: "More energy efficient",
      src:
        "https://cdni.iconscout.com/illustration/premium/thumb/audit-5631243-4689116.png?f=webp",
      info:
        "Energy audits allow you to assess how energy is used in your building and to identify opportunities for increasing efficiency. These opportunities may involve changing operating practices to reduce energy consumption or retrofitting the building to make it more energy efficient",
      delay: "0.8s",
    },
    {
      id: 4,
      title: "Building operations and maintenance",
      src:
        "https://www.intelex.com/wp-content/uploads/2021/03/incident-reporting-dashboards.png",
      info:
        "Since buildings consume more than 40% of energy and 12% of water, achieving more sustainable building operations can contribute toward significant environmental improvements. When properly operated for efficiency, well-maintained buildings can have 20-50% lower operating costs while drastically reducing environmental impact.",
      delay: "0.6s",
    },
    {
      id: 5,
      title: "Benchmarking",
      src:
        "https://miro.medium.com/v2/resize:fit:1200/1*0Fx4Pg_jSHiS3oaRsBhGmw.png",
      info:
        "Benchmarking is establishing a baseline of energy or water use over at least a full-year period. Benchmarking helps manage energy and water consumption by using metrics to track, monitor, and assess energy and water use. The process of benchmarking identifies potential savings and prioritizes necessary improvements.",
      delay: "0.4s",
    },
    {
      id: 6,
      title: "EMS Benchmarking",
      src:
        "https://www.enkel.ca/wp-content/uploads/2021/04/NPO-first-time-audit.png",
      info: [
        "Professional, class-leading energy monitoring solutions",
        "Real-time, multi-channel metering hardware supported by proprietary software",
        "Measurement of energy flows from different sources",
        "Full integration with the existing building engineering systems",
        "Remote monitoring",
        "Configurable and programmable hardware components",
        "Measurement and verification opportunity",
        "Etc",
      ],
      delay: "0.8s",
    },
  ];

  export default auditCards;