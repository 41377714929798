const cardsList = [
    {
      id: 1,
      src: 'https://t3.ftcdn.net/jpg/05/42/22/74/360_F_542227467_R6DW28JjThc2LxJK0sPVTxKrXsGodi84.jpg',
      name: "Become most Dependability",
      info: "Aiming at improving the reliability and consistency of a company's operations. This can be achieved through measures such as implementing backup systems, maintaining equipment and facilities, and ensuring adequate staffing levels.",
    },
    {
      id: 2,
      src: 'https://us.123rf.com/450wm/luplupme/luplupme2109/luplupme210900065/173892742-business-office-work-with-time-management-vector-illustration-man-woman-people-character-use.jpg',
      name: "Become most Flexibility",
      info: "Seeking to improve the ability of a company to adapt to changing circumstances. This can be achieved through measures such as cross-training employees, diversifying product lines, and implementing flexible work arrangements.",
    },
    {
      id: 3,
      src: 'https://t3.ftcdn.net/jpg/01/30/11/74/360_F_130117437_bZkpV4f0aEjtimpqF08Lbs8ucanrlUkD.jpg',
      name: "Become most Speed",
      info: "Focused on improving the speed and responsiveness of a company's operations. This can be achieved through measures such as reducing lead times, improving communication and coordination between departments, and investing in faster technology.",
    },
    // {
    //   id: 4,
    //   name: "Become most Flexibility",
    //   info: "seeking to improve the ability of a company to adapt to changing circumstances. This can be achieved through measures such as cross-training employees, diversifying product lines, and implementing flexible work arrangements.",
    // },
    // {
    //   id: 5,
    //   name: "Become most Flexibility",
    //   info: "seeking to improve the ability of a company to adapt to changing circumstances. This can be achieved through measures such as cross-training employees, diversifying product lines, and implementing flexible work arrangements.",
    // },
  ];

  export default cardsList;