import { combineReducers } from 'redux';

import * as slices from '../../slices';

const combinedReducer = combineReducers({
  ...slices,
});

const reducer = (state, action) => {
  return combinedReducer(state, action);
};

export default reducer;
