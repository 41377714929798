import styled from "styled-components";

export const RightBlockContainer = styled("section")`
  position: relative;
  padding: 90px 50px 30px;

  @media only screen and (max-width: 1024px) {
    padding: 36px 50px 24px;
  }

  @media only screen and (max-width: 768px) {
    padding: 24px 24px 24px;
  }
`;

export const Content = styled("p")`
  margin: 1.5rem 0 2rem 0;
  font-size: 18px !important;

  @media only screen and (max-width: 768px) {
    margin: 6px 0;
  }
`;

export const ListContent = styled("p")`
margin: 6px 0;
`;

export const ContentWrapper = styled("div")`
  position: relative;
  max-width: 540px;

  @media only screen and (max-width: 575px) {
    padding-bottom: 4rem;
  }
`;

export const ListWrapper = styled("div")`
  position: relative;
  max-width: 100%;
  width: 100%;
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 24px 0;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 0 24px;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  max-width: 100%;

  @media screen and (min-width: 1024px) {
    max-width: 80%;
  }

  button:last-child {
    margin-left: 20px;
  }
`;
