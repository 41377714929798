const aboutSectionInfo = [
    {
      idx: 1,
      type: "right",
      title: "Who We Are",
      content:
        "Equipment Service - Utilizing expert technicians, we support ongoing service and maintenance, including 24/7 availability. Whether you require routine maintenance or consultation regarding a new area of concern, O&M LLC is readily available for the lifetime of your building. Measurement and Verification -Our measurement and verification are structured to capture the effect of multiple variables on energy consumption.We analyze energy use for the whole building rather than focusing on the area or systems affected by a project.This gives you a complete picture of your facility's consumption and savings.",
      icon: "aboutUs/ab21.jpg",
      id: "intro",
    },
    {
      idx: 2,
      type: "left",
      title:
        "How we offer",
      section: [
        {
          title: "Dedicated Computerized Management, Control, Monitoring, and Maintenance",
          content: "We offer an implementation of a wide range of state-of-the-art dedicated software platforms and IoT technologies for automatic fault detection, utility management, measurement and verification, and continuous data collection and archival.",
        },
        {
          title: "Utility Management",
          content: "We offer complete solutions for managing utility costs. Our dedicated team of analysts provides rate design, rate analysis, procurement, peak shaving, market monitoring, forecasting, budgeting, bill auditing, and more. We take a detailed and hands-on approach to deliver tangible results.",
        }
      ],
      icon: "aboutUs/ab10.jpg",
      id: "offer",
    },
    {
      idx: 3,
      type: "middle",
      title: "Experience and Advantages of Company",
      content:
        "Experience of carrying out energy audits at similar buildings and discovering issues raised with energy systems in short period of time. Availability of energy monitoring and energy auditing equipment and tools.",
      id: "Experience",
    },
    {
      idx: 4,
      type: "right",
      title: "Our Portfolio of Services",
      content: [
        "Facility Construction",
        "System descriptions",
        "Master equipment lists",
        "Spare parts determination",
        "Critical components determination",
        "Procedure development",
        "Operating personnel assistance",
        "Specialty maintenance and technical tools",
        "Facility Startup",
        "P&ID verification",
        "Energy Audit",
        "Benchmarking",
        "Perform startup",
        "Facility Operations",
        "Plant management",
        "Body shop for outages",
        "Certified welding activities",
        "Non-routine maintenance activities ",
        "System analysis and efficiency studies",
        ",Scheduling activities (constructions, startup, operations, PMs, outage)",
        "Major maintenance activities, supported by advanced Computerized Maintenance Management System",
        "Full project management (licensing, design, construction, maintenance)",
      ],
      id: "portfolio",
    },
    {
      idx: 5,
      type: "left",
      title: "Rooftop Packaged Unit Preventive Maintenance",
      section: [
        {
          title: "Quarterly maintenance of Rooftop Packaged Unit",
          content: "Styled Components gives you total control over your components.",
        },
        {
          title: "Semiannual maintenance of Rooftop Packaged Unit",
          content: "Styled Components gives you total control over your components.",
        },

        {
          title: " Annual maintenance of Heat Recovery Wheel",
          content: "Styled Components gives you total control over your components.",
        }, {
          title: "Annual maintenance of Rooftop Packaged Unit",
          content: "Styled Components gives you total control over your components.",
        },
      ],
      icon: "aboutUs/ab5.jpg",
      id: "Rooftop",
    },
    {
      idx: 6,
      type: "right",
      title: "Air Handling Unit(AHU) Preventive Maintenance",
      content: ['Quarterly maintenance of AHU', 'Semi annual maintenance of AHU', 'Annual maintenance of AHU'],
      icon: "aboutUs/ab2.jpg",
      id: "AHU",
    },
    {
      idx: 7,
      type: "left",
      title: "Heat Recovery Wheel Preventive Maintenance",
      content: 'Quarterly maintenance of Heat Recovery Wheel - Check if the heat wheel rotates freely, Semiannual maintenance of Heat Recovery Wheel - Check the heat wheel drive belt tension, check for belt wear, and check the drive pulley for evidence of wear, Check for proper operation of heat exchange, Check control system and devices for evidence of improper operation, Check heat wheel for visible signs of biological growth check condition, setting, and operation of the drive motor,, Annual maintenance of Heat Recovery Wheel -Check control box for dirt, debris, and/or lose termination.',
      icon: "aboutUs/ab4.jpg",
      id: "Recovery",
    },
    {
      idx: 8,
      type: "right",
      title: "Fan Coil Unit (FCU) Preventive Maintenance",
      content: ['Quarterly maintenance of FCU', 'Semiannual maintenance of FCU', 'Annual maintenance of FCU'],
      icon: "aboutUs/ab6.jpg",
      id: "Coil",
    },
    {
      idx: 9,
      type: "left",
      title: "Chiller Preventive Maintenance",
      section: [
        {
          icon: 'aboutUs/ab7.jpg',
          content: "Monthly maintenance of Chiller, Quarterly maintenance of Chiller, Semiannual maintenance of Chiller, Annual maintenance of Chiller",
        }],
      icon: "aboutUs/ab8.jpg",
      id: "Chiller",
    },
    {
      idx: 10,
      type: "right",
      title: "Cooling Tower Preventive Maintenance",
      content: ['Monthly maintenance of Cooling Tower', 'Quarterly maintenance of Cooling Tower', 'Semiannual maintenance of Cooling Tower', 'Annual maintenance of Cooling Tower'],
      icon: "aboutUs/ab9.jpg",
      id: "Cooling",
    },
    {
      idx: 11,
      type: "left",
      title: "Boiler Preventive Maintenance",
      section: [
        {
          content: '✔ Monthly maintenance of Boiler'
        },
        {
          content: '✔ Quarterly maintenance of Boiler'
        },
        {
          content: '✔ Semiannual maintenance of Boiler'
        },
        {
          content: '✔ Annual maintenance of Boiler'
        },
      ],
      icon: "aboutUs/ab11.jpg",
      id: "Cooling",
    },
    {
      idx: 12,
      type: "right",
      title: "Pump Preventive Maintenance ",
      content: ['Semiannual maintenance of Pump', 'Annual maintenance of Pump'],
      icon: "aboutUs/ab1.jpg",
      id: "Pump",
    },
    {
      idx: 13,
      type: "left",
      title: "Building Management System (BMS) Maintenance",
      section: [
        {
          content: '✔ Monthly maintenance of Building Management System',
          icon: "aboutUs/ab13.jpg",

        },
        {
          content: '✔ Quarterly maintenance of Building Management System',
          icon: "aboutUs/ab14.jpg",

        },
        {
          content: '✔ Semiannual maintenance of Building Management System',

        },
        {
          content: '✔ Annual maintenance of Building Management System',

        },
      ],
      icon: "aboutUs/ab19.png",
      id: "BMS",
    },
    {
      idx: 14,
      type: "right",
      title: "AHU Filters Supply and Trading",
      content: 'The Ahu filter is an invaluable tool for any HVAC system, as it helps to improve air quality and reduce allergens. It also helps to keep heating and cooling systems running more efficiently and cost-effectively. With its filter-less design, easy maintenance, and long life span, the Ahu filter is a great choice for both residential and commercial HVAC systems.',
      icon: "aboutUs/ab15.jpg",
      id: "Pump",
    },
  ];

  export default aboutSectionInfo;