import {
  Main,
  AboutUs,
  OurTeam,
  OurWorks,
  Partners,
  Goals,
  // HowWeWork,
  // JoinOurTeam,
} from "../pages/Home/index";

const mainSections = [Main, AboutUs, Goals, OurWorks, Partners, OurTeam];

export default mainSections;
