import React from "react";
import PropTypes from "prop-types";

import { FullPageContext } from "../../context/index";
import Header from "../../pages/Header/index";
import FooterContainer from "../../pages/Footer";

const FullPageLayout = ({ children, _, fullpageApi }) => {
  return (
    <FullPageContext.Provider value={{ moveToSection: fullpageApi }}>
      <Header isOnePage />
      {children}
      <FooterContainer />
    </FullPageContext.Provider>
  );
};

FullPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  fullpageApi: PropTypes.object,
};

FullPageLayout.defaultProps = {
  fullpageApi: {},
};

export default FullPageLayout;
