import styles from '../pages/Home/OurWorks/OurWorks.module.scss';

const ourSliderData = [
  {
    title: 'Kamar Business Center',
    subTitle: 'BREEAM certificated',
    textInfo:
      'Providing expert O&M, consulting, BMS and engineering services since 2018 up to now',
    link: 'https://kamarcenter.am',
    projectPage: 'kamarcenter.am',
    bg: '/img/images/works/Picture2.jpg',
    className: styles.text_block__six,
    activeClassName: styles.text_block__six__active,
  },
  {
    title: 'Erebuni Plaza',
    subTitle:
      'Multifunctional Business Center',
    textInfo:
      'Providing expert O&M, consulting, and engineering services since 2022 up to now.',
    link: 'https://erebuni-plaza.am',
    projectPage: 'erebuni-plaza.am',
    bg: '/img/images/works/Picture1.jpg',
    className: styles.text_block__five,
    activeClassName: styles.text_block__five__active,
  },
  {
    title: 'American University of Armenia',
    subTitle:
      'Paramaz Avedisian Building',
    textInfo:
      'Providing expert O&M, consulting, and BMS services since2017 up to now.',
    link: 'https://www.aua.am',
    projectPage: 'aua.am',
    bg: '/img/images/works/Picture4.jpg',
    className: styles.text_block__four,
    activeClassName: styles.text_block__four__active,
  },
  {
    title: 'American University of Armenia',
    subTitle: 'Main Building',
    textInfo:
      'Providing expert O&M, consulting, and BMS services from 2017 to now.',
      link: 'https://www.aua.am',
      projectPage: 'aua.am',
      bg: '/img/images/works/Picture3.jpg',
    className: styles.text_block__six,
    activeClassName: styles.text_block__six__active,
  },
  {
    title: 'Yerevan Mall',
    subTitle:
      'Shopping and Entertainment Complex',
    textInfo:
      'Providing expert O&M, consulting, and BMS services from 2017 to now.',
    link: 'https://apps.apple.com/by/app/eiev/id1529308218',
    projectPage: 'YerevanMall',
    bg: '/img/images/works/Picture5.jpg',
    className: styles.text_block__three,
    activeClassName: styles.text_block__three__active,
  },
  {
    title: 'American University of Armenia',
    subTitle:
      'Center',
    textInfo:
      'Providing expert O&M, consulting, and BMS services from 2017 to now.',
      link: 'https://www.aua.am',
      projectPage: 'aua.am',
      bg: '/img/images/works/Picture6.jpg',
    className: styles.text_block__two,
    activeClassName: styles.text_block__two__active,
  },
  {
    title: 'American University of Armenia',
    subTitle:
      'Student Residence',
    textInfo:
      'Providing expert O&M, consulting, and BMS services from 2021 up to now.',
    link: 'https://yerevanmall.am',
    projectPage: 'yerevanmall.am',
    bg: '/img/images/works/Picture7.jpg',
    className: styles.text_block__three,
    activeClassName: styles.text_block__three__active,
  },
  {
    title: 'Yerevan City',
    subTitle: 'Supermarket Chain',
    textInfo:
      'Providing expert O&M, consulting, and BMS services since 2019 up to now',
    link: 'https://yerevan-city.am',
    projectPage: 'yerevan-city.am',
    bg: '/img/images/works/Picture9.png',
    className: styles.text_block__one,
    activeClassName: styles.text_block__one__active,
  },
  {
    title: 'American University of Armenia',
    subTitle:
      'Najarian Center',
    textInfo:
      'Providing consulting, and commissioning services from 2021 up to now.',
      link: 'https://www.aua.am',
      projectPage: 'aua.am',
      bg: '/img/images/works/Picture8.jpg',
    className: styles.text_block__four,
    activeClassName: styles.text_block__four__active,
  },
];

export default ourSliderData;
