import React, { useRef, useEffect } from "react";
import usePortal from "react-useportal";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { useOutsideClick, useLockBodyScroll } from "../../../../hooks/index";
import { activeIndexSelector } from "../../../../slices/mainSlice";

import styles from "./Modal.module.scss";

const Modal = ({ data, setIsOpenModal, isOpenModal }) => {
  const activeIndex = useSelector(activeIndexSelector);
  const { Portal } = usePortal();
  const infoRef = useRef(null);
  const { name, profession, experience, education } = data;

  useOutsideClick(infoRef, () => {
    if (isOpenModal) {
      setIsOpenModal(false);
    }
  });

  useEffect(() => {
    if ( activeIndex !==6) {
      setIsOpenModal(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])
  
  useLockBodyScroll();

  const wrapperClasses = classNames(styles.wrapper, {
    [styles.wrapper__open]: isOpenModal,
  });

  const innerInfoClasses = classNames(styles.info, {
    [styles.info__open]: isOpenModal,
  });
  return (
    <Portal>
      <div role='button' className={wrapperClasses}>
        <div ref={infoRef} className={innerInfoClasses}>
          <h2 className={styles.info__title}>{name}</h2>
          <div>
            <div className={styles.wrapper__info}>
              <h4 className={styles.info__subtitle}>Present occupation ━</h4>
              <p className={styles.info__text}>{profession}</p>
            </div>
            {experience && (
              <div className={styles.wrapper__info}>
                <h4 className={styles.info__subtitle}>Work experience ━</h4>
                <p className={styles.info__text}>{experience}</p>
              </div>
            )}
            <div className={styles.wrapper__info}>
              <h4 className={styles.info__subtitle}>Education ━</h4>
              <p className={styles.info__text}>{education}</p>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

Modal.propTypes = {
  data: PropTypes.object,
  isOpenModal: PropTypes.bool,
  setIsOpenModal: PropTypes.func,
};

Modal.defaultProps = {
  data: {},
  isOpenModal: false,
  setIsOpenModal: () => {},
};

export default Modal;
