import React, { useRef, useEffect } from "react";
import usePortal from "react-useportal";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { useOutsideClick, useLockBodyScroll } from "../../../../hooks/index";
import { activeIndexSelector } from "../../../../slices/mainSlice";

import styles from "./Modal.module.scss";
import Text from "../../../../components/Text";
import { CloseIcon } from "../../../../icons";

type ModalProps = {
  data: string;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenModal: boolean;
};

const Modal = ({ data, setIsOpenModal, isOpenModal }: ModalProps) => {
  const activeIndex = useSelector(activeIndexSelector);
  const { Portal } = usePortal();
  const infoRef = useRef(null);

  const handlerClose = () => setIsOpenModal(false);

  useOutsideClick(infoRef, () => {
    if (isOpenModal) {
      handlerClose();
    }
  });

  useEffect(() => {
    if (activeIndex !== 3) {
      handlerClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  useLockBodyScroll();

  const wrapperClasses = classNames(styles.wrapper, {
    [styles.wrapper__open]: isOpenModal,
  });

  const innerInfoClasses = classNames(styles.info, {
    [styles.info__open]: isOpenModal,
  });

  return (
    <Portal>
      <div className={wrapperClasses}>
        <div ref={infoRef} className={innerInfoClasses}>
          <div
            role='button'
            onClick={handlerClose}
            className={styles.wrapper__icon}>
            <CloseIcon />
          </div>
          <Text className={styles.wrapper__info}>{data}</Text>
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
