import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { YMaps, Map, Placemark, ZoomControl } from "react-yandex-maps";

import Container from "../../common/Container";

// import i18n from "i18next";
import {
  WrapperContent,
  Extra,
  LogoContainer,
  MapContainer,
  RightBlock,
  MobileAddress,
  FooterTopContainer,
  WebBottomContent,
  Para,
  Large,
  Chat,
  Empty,
  NavLink,
  Language,
} from "./styles";
import { FbIcon, Instagram, LogoIcon, TelegramIcon } from "../../icons";

interface SocialLinkProps {
  href: string;
  src: string;
  icon: any;
}

const Footer = ({ t }: any) => {
  // const handleChange = (language: string) => {
  //   i18n.changeLanguage(language);
  // };

  const SocialLink = ({ href, src, icon: Icon }: SocialLinkProps) => {
    return (
      <NavLink href={href} rel='noopener noreferrer' key={src} aria-label={src}>
        <Icon />
      </NavLink>
    );
  };

  return (
    <>
      <WrapperContent>
        <RightBlock>
          <Row justify='space-between'>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Language>{t("Contact")}</Language>
              <Large>{t("Tell us everything")}</Large>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href='mailto:info@oandmservice.am'>
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
          </Row>
          <Row justify='start'>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Empty />
              <Language>{t("Phone number")}</Language>
              <Para
                style={{
                  display: "flex",
                  alignItems: "center",
                }}>
                <Large style={{ fontSize: "40px", marginRight: "8px" }}>
                  ✆
                </Large>
                +374 44 25 22 22
              </Para>
            </Col>
          </Row>
          <MobileAddress>
            <Col
              style={{ justifyContent: "flex-start", display: "flex" }}
              lg={24}
              md={24}
              sm={24}
              xs={24}>
              <Row style={{ display: "block", margin: 0 }}>
                <Language>{t("Address")}</Language>
                <Para>Erebuni Plaza, BC</Para>
                <Para>Vazgen Sargsyan 26/1 Street</Para>
                <Para>Room 308</Para>
              </Row>
            </Col>
          </MobileAddress>
        </RightBlock>
        <MapContainer>
          <YMaps>
            <Map
              style={{ width: "100%", height: "100%" }}
              defaultState={{
                center: [40.176395, 44.512439],
                zoom: 10,
              }}>
              <ZoomControl options={{ position: { top: 10, right: 10 } }} />
              <Placemark geometry={[40.176395, 44.512439]} />
            </Map>
          </YMaps>
        </MapContainer>
      </WrapperContent>
      <Extra>
        <FooterTopContainer border={true}>
          <WebBottomContent>
            <Col
              style={{ justifyContent: "flex-start", display: "flex" }}
              lg={12}
              md={12}
              sm={12}
              xs={12}>
              <Row style={{ display: "block", margin: 0 }}>
                <Language>{t("Address")}</Language>
                <Para>Erebuni Plaza, BC</Para>
                <Para>Vazgen Sargsyan 26/1 Street</Para>
                <Para>Room 308</Para>
              </Row>
            </Col>
          </WebBottomContent>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "12px",
              paddingBottom: "12px",
            }}
            lg={12}
            md={12}
            sm={24}
            xs={24}>
            <Row>
              <SocialLink
                href='https://www.facebook.com/oandmservice?mibextid=ZbWKwL'
                src='facebook'
                icon={FbIcon}
              />
              <SocialLink
                href='https://www.facebook.com/oandmservice?mibextid=ZbWKwL'
                src='instagram'
                icon={Instagram}
              />
              <SocialLink
                href='https://www.facebook.com/oandmservice?mibextid=ZbWKwL'
                src='telegram'
                icon={TelegramIcon}
              />
            </Row>
            <Row>
              <Para style={{ textAlign: "center" }}>
                ©2022 O&M Service LLC. All rights reserved.
              </Para>
            </Row>
          </Col>
          <WebBottomContent style={{ justifyContent: "flex-end" }}>
            <Col
              style={{
                justifyContent: "flex-end",
                display: "flex",
              }}
              lg={12}
              md={12}
              sm={12}
              xs={12}>
              <LogoContainer
                style={{
                  width: "70px",
                  height: "auto",
                }}>
                <LogoIcon />
              </LogoContainer>
            </Col>
          </WebBottomContent>
        </FooterTopContainer>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
