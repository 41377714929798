import { useState, useEffect } from "react";
import Header from "../Header/index";
import Container from "../../common/Container";
import MiddleBlock from "../../components/MiddleBlock";
import ScrollToTop from "../../common/ScrollToTop";
import ContentBlock from "../../components/ContentBlock";
import FooterContainer from "../Footer/index";
import { aboutSectionInfo } from "../../utils/index";

const About = () => {
  useEffect(() => {
    const handleRouteChange = () => {
      const body = document.body;

      body.style.overflow = "auto";
      body.style.touchAction = "auto";
    };

    handleRouteChange();
  }, []);

  return (
    <Container>
      <Header />
      <ScrollToTop />
      {aboutSectionInfo.map((el) => {
        if (el.type === "right") {
          return (
            <ContentBlock
              key={`about-ContentBlock-right ${el.idx}`}
              type={el.type}
              title={el.title}
              content={el.content}
              icon={el.icon as string}
              id={el.id}
            />
          );
        } else if (el.type === "left") {
          return (
            <ContentBlock
              key={`about-ContentBlock-left ${el.idx}`}
              type={el.type}
              title={el.title}
              content={el.content}
              section={el.section}
              icon={el.icon as string}
              id={el.id}
            />
          );
        } else {
          return (
            <MiddleBlock
              key={`about-MiddleBlock ${el.idx}`}
              title={el.title}
              content={el.content as string}
            />
          );
        }
      })}
      <FooterContainer />
    </Container>
  );
};

export default About;
