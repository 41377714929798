import styled from "styled-components";

export const MiddleBlockSection = styled("section")`
  position: relative;
  padding: 90px 50px 30px;
  text-align: center;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 1024px) {
    padding: 36px 50px 24px;
  }

  @media only screen and (max-width: 768px) {
    padding: 24px 24px 24px;
  }
`;

export const Content = styled("p")`
  padding: 0.75rem 0 0.75rem;
`;

export const ContentWrapper = styled("div")`
  max-width: 570px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;
