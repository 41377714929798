import { useEffect } from "react";
import Heading from "../../components/Heading";
import Header from "../Header/index";
import AuditCard from "../../common/AuditCart";
import Container from "../../common/Container";
import ScrollToTop from "../../common/ScrollToTop";
import MiddleBlock from "../../components/MiddleBlock";
import { auditCards } from "../../utils";
import FooterContainer from "../Footer/index";

import styles from "./Audit.module.scss";

const Audit = () => {
  const auditInfo =
    "As a result of changes in the energy marketplace, the role of energy audits has greatly expanded. There is an increasing need in business and industry to more effectively manage energy use. Reducing utility costs is still one of the most effective and achievable strategies for lowering operating costs.";

  useEffect(() => {
    const handleRouteChange = () => {
      const body = document.body;

      body.style.overflow = "auto";
      body.style.touchAction = "auto";
    };

    handleRouteChange();
  }, []);

  const renderAuditCards = auditCards.map((el) => (
    <AuditCard
      key={`audit ${el.id}`}
      title={el.title}
      delay={el.delay}
      info={el.info}
      src={el.src}
    />
  ));

  return (
    <Container>
      <Header />
      <ScrollToTop />
      <div className={styles.wrapper}>
        <div className={styles.wrapper__fist_block}>
          <div id='intro' className={styles.wrapper__fist_block__info}>
            <Heading className={styles.wrapper__fist_block__title}>
              The Energy Audit
            </Heading>
            <img
              className={styles.wrapper__fist_block__img}
              src='https://cdni.iconscout.com/illustration/premium/thumb/audit-services-4489837-3766576.png'
              alt='audit'
            />
          </div>
          <div className={styles.wrapper__fist_block__icon}>
            <img
              src='/img/images/auditBg.png'
              alt='auditBg.png'
              width='100%'
              height='100%'
            />
          </div>
        </div>
        <MiddleBlock
          title='What the Energy Audit give you'
          content={auditInfo}
        />
        <div className={styles.wrapper__content}>{renderAuditCards}</div>
      </div>
      <FooterContainer />
    </Container>
  );
};

export default Audit;
