import { useState } from "react";
import { useSelector } from "react-redux";

import Text from "../../../components/Text";
import { teamCards } from "../../../utils/index";
import { activeIndexSelector } from "../../../slices/mainSlice";

import Modal from "./Modal";
import styles from "./OurTeam.module.scss";

const OurTeam = () => {
  const activeIndex = useSelector(activeIndexSelector);
  const [activeEmployee, setActiveEmployee] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const isOpen = activeIndex === 6;

  const handlerModal = (id) => {
    setActiveEmployee(id);
    setIsOpenModal(true);
  };

  const renderTeamItems = teamCards.map((el) => (
    <div
      role='button'
      onClick={() => handlerModal(el.id)}
      className={styles.wrapper__container__item}
      style={{
        left: el.left,
        animationDelay: el.delay,
        animationPlayState: isOpenModal || !isOpen ? "paused" : "running",
      }}
      key={el.id}>
      <img
        className={styles.wrapper__container__item__img}
        alt={el.name}
        src={el.src}
      />
      <Text className={styles.wrapper__container__item__name}>{el.name}</Text>
    </div>
  ));

  return (
    <section className={`section ${styles.wrapper}`}>
      <div className={`container  ${styles.wrapper__container}`}>
        <h2 className={styles.wrapper__container__title}>Our Team</h2>
        <div className={` ${styles.wrapper__container__content}`}>
          {renderTeamItems}
        </div>
      </div>
      <Modal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        data={teamCards[activeEmployee - 1]}
      />
    </section>
  );
};

export default OurTeam;
