import { useCallback, useMemo, useState } from "react";
import classNames from "classnames";

import Heading from "../../../components/Heading";
import { ourSliderData } from "../../../utils/index";
import { useWindowSize } from "../../../hooks/index";

import styles from "./OurWorks.module.scss";

const OurWorks = () => {
  const { windowHeight, windowWidth, isMobile } = useWindowSize();
  const [curSlide, setCurSlide] = useState(0);

  const transformValue = useMemo(
    () =>
      !isMobile
        ? `translate3d(0px, -${curSlide * windowHeight}px, 0px)`
        : `translate3d(-${curSlide * windowWidth}px, 0px, 0px)`,
    [isMobile, curSlide, windowWidth, windowHeight],
  );

  const navigateDown = useCallback(() => {
    if (curSlide === ourSliderData.length - 1) {
      setCurSlide(0);
    } else {
      setCurSlide(curSlide + 1);
    }
  }, [curSlide]);

  const navigateUp = useCallback(() => {
    console.log(1);
    if (curSlide === 0) {
      console.log(2);
      setCurSlide(ourSliderData.length - 1);
    } else {
      console.log(3);
      setCurSlide(curSlide - 1);
    }
  }, [curSlide]);

  const renderSliderItem = ourSliderData.map(
    (
      {
        bg,
        link,
        title,
        subTitle,
        textInfo,
        className,
        projectPage,
        activeClassName,
      },
      idx,
    ) => (
      <div className={styles.block} key={`${title}${idx}`}>
        <div
          className={classNames(
            styles.text_block,
            curSlide !== idx ? className : activeClassName,
          )}
        />
        <div className={styles.info}>
          <h1 className={styles.info__title}>{title}</h1>
          <h2 className={styles.info__subtitle}>{subTitle}</h2>
          <p className={styles.info__text}>{textInfo}</p>
          {link ? (
            <a
              className={styles.info__link}
              href={link}
              anchorProps={{
                target: "_blank",
                rel: "noreferrer",
                "aria-label": title,
              }}>
              {projectPage}
            </a>
          ) : (
            <span className={styles.info__link}>{projectPage}</span>
          )}
        </div>

        <div
          style={{ backgroundImage: `url(${bg})` }}
          className={styles.wrapper__content__item}
        />
      </div>
    ),
  );

  return (
    <section className='section'>
      <div className={styles.wrapper}>
        <Heading className={styles.wrapper__title} isTitle>
          Our Works
        </Heading>
        <div onClick={navigateUp} className={styles.wrapper__prev}>
          prev
        </div>
        <div className={styles.wrapper__container}>
          <div
            className={styles.wrapper__content}
            style={{
              transform: transformValue,
            }}>
            {renderSliderItem}
          </div>
        </div>
        <div onClick={navigateDown} className={styles.wrapper__next}>
          next
        </div>
      </div>
    </section>
  );
};

export default OurWorks;
