import {ManIcon} from "../icons/index";

const teamCard = [
  {
    id: 1,
    src: ManIcon,
    name: "Haroutioun Balian",
    profession: 'Senior HVAC Engineer',
    experience: '30 years',
    education: 'University degree',
    left: "-10%",
    delay: "0s",
  },
  {
    id: 2,
    src: ManIcon,
    name: "Sergey Maghakyan",
    profession: 'Chief Engineer',
    experience: '48 years',
    education: 'Engineer degree',
    left: "89%",
    delay: "1s",
  },
  {
    id: 3,
    src: ManIcon,
    name: "Smbat Badalyan",
    profession: 'Chief manager',
    experience: '11 years',
    education: 'Master degree',
    left: "-10%",
    delay: "2s",
  },
  {
    id: 4,
    src: ManIcon,
    name: "Hovhannes Navasardyan",
    profession: 'Technician-engineer',
    experience: '4 years',
    education: 'University degree',
    left: "89%",
    delay: "3s",
  },
  {
    id: 5,
    src: ManIcon,
    name: "Norair Sargsyan",
    profession: 'Junior Engineers',
    experience: '4 years',
    education: 'University degree',
    left: "-10%",
    delay: "4s",
  },
  {
    id: 6,
    src: ManIcon,
    name: "Argam Tshughunyan",
    profession: 'BMS operator',
    experience: '14 years',
    education: 'Master degree',
    left: "89%",
    delay: "5s",
  },
  {
    id: 7,
    src: ManIcon,
    name: "Samvel Vardanyan",
    profession: 'BMS operator',
    experience: '11 years',
    education: 'University degree',
    left: "-10%",
    delay: "6s",
  },
  {
    id: 8,
    src: ManIcon,
    name: "Tigran Mkrtchyan",
    profession: 'Plumber',
    experience: '42 years',
    education: 'University degree',
    left: "89%",
    delay: "7s",
  },
  {
    id: 9,
    src: ManIcon,
    name: "Gevorg Melkonyan",
    profession: 'BMS operator',
    education: 'University degree',
    left: "-10%",
    delay: "8s",
  },
  {
    id: 10,
    src: ManIcon,
    name: "Mihran Keshishyan",
    profession: 'Stoker',
    experience: '30 years',
    education: 'High school',
    left: "89%",
    delay: "9s",
  },
  {
    id: 11,
    src: ManIcon,
    name: "Davit Manoukyan",
    profession: 'BMS operator',
    experience: '5 years',
    education: 'High school',
    left: "-10%",
    delay: "10s",
  },
  {
    id: 12,
    src: ManIcon,
    name: "Narek Hakobyan",
    profession: 'Electrician',
    experience: '3 years',
    education: 'High school',
    left: "89%",
    delay: "11s",
  },
  {
    id: 13,
    src: ManIcon,
    name: "Avetik Khunoyan",
    profession: 'Electrician',
    experience: '5 years',
    education: 'University degree',
    left: "-10%",
    delay: "12s",
  },
  {
    id: 14,
    src: ManIcon,
    name: "Davit Manoukyan",
    profession: 'BMS operator',
    experience: '5 years',
    education: 'High school',
    left: "89%",
    delay: "13s",
  },
  {
    id: 15,
    src: ManIcon,
    name: "Ararat Navasardyan",
    profession: 'Mechanical engineer',
    experience: '7 years',
    education: 'University degree',
    left: "-10%",
    delay: "14s",
  },
  {
    id: 16,
    src: ManIcon,
    name: "Harout Almasian",
    profession: 'Consulting Engineer',
    experience: '12 years',
    education: 'University degree',
    left: "89%",
    delay: "15s",
  },
  {
    id: 17,
    src: ManIcon,
    name: "Sergey Arakelyan",
    profession: 'Electrician',
    experience: '26 years',
    education: 'High school',
    left: "-10%",
    delay: "16s",
  },
  {
    id: 18,
    src: ManIcon,
    name: "Israel Khachatryan",
    profession: 'BMS operator',
    experience: '5 years',
    education: 'High school',
    left: "89%",
    delay: "17s",
  },
  {
    id: 19,
    src: ManIcon,
    name: "Haik Navasardyan",
    profession: 'Electrician',
    education: 'University degree',
    left: "-10%",
    delay: "18s",
  },
  {
    id: 20,
    src: ManIcon,
    name: "Smbat Sargsyan",
    profession: 'Stoker',
    experience: '5 years',
    education: 'High school',
    left: "89%",
    delay: "19s",
  }, 
];

export default teamCard;
