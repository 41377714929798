import Home from "../pages/Home/FullPage/index";
import About from "../pages/About/index";
import Audit from "../pages/Audit/index";

const routes = [
  {
    id: 1,
    path: ["/", "/home"],
    exact: true,
    component: Home,
  },
  {
    id: 2,
    path: ["/about", "/about"],
    exact: false,
    component: About,
  },
  {
    id: 3,
    path: ["/audit", "/audit"],
    exact: false,
    component: Audit,
  },
];

export default routes;
