import classNames from "classnames";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Text from "../../../components/Text";
import { aboutTextList } from "../../../utils/index";
import { activeIndexSelector } from "../../../slices/mainSlice";

import styles from "./AboutUs.module.scss";
import { useWindowSize } from "../../../hooks";

const AboutUs = () => {
  const history = useHistory();
  const { isMobile } = useWindowSize();
  const activeIndex = useSelector(activeIndexSelector);
  const textCount = aboutTextList && isMobile ? 4 : aboutTextList?.length;

  const moveAbout = () => history.push("/about");
  const moveAudit = () => history.push("/audit");

  const renderTextList =
    aboutTextList &&
    aboutTextList
      .slice(0, textCount)
      .map((el) => (
        <Text className={styles.wrapper__content__text_item}> ✔ {el}</Text>
      ));

  return (
    <section className={`${styles.height_response} section`}>
      <div className={`container ${styles.wrapper}`}>
        <div className={styles.wrapper__left_block}>
          <svg
            className={classNames(styles.wrapper__title, {
              [styles.wrapper__title_anima]: activeIndex === 2,
            })}
            viewBox='0 0 100% 100%'>
            <text x='50%' y='35%' dy='.35em' textAnchor='middle'>
              About
            </text>
            <text x='50%' y='65%' dy='.35em' textAnchor='middle'>
              Us
            </text>
          </svg>
          <Text className={styles.wrapper__subtitle}>
            Our company has started work since 2016 and has about 100 employees.
          </Text>
        </div>
        <div className={styles.wrapper__content}>
          <div
            className={classNames(styles.wrapper__content__first_block, {
              [styles.wrapper__content__first_block_anima]: activeIndex === 2,
            })}
          />
          {/* <div
            className={classNames(styles.wrapper__content__second_block, {
              [styles.wrapper__content__second_block_anima]: activeIndex === 2,
            })}
          /> */}
          <div
            className={classNames(styles.wrapper__content__text, {
              [styles.wrapper__content__text_anima]: activeIndex === 2,
            })}>
            <div className={styles.wrapper__content__text_bg} />
            {renderTextList}
            <div className={styles.wrapper__button_block}>
              <Button className={styles.wrapper__button} onClick={moveAbout}>
                About woks ➬
              </Button>
              <Button className={styles.wrapper__button} onClick={moveAudit}>
                About Audit ➬
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
