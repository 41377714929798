import { useState } from "react";

import Text from "../../../components/Text";
import Modal from "./Modal";
import Heading from "../../../components/Heading";
import { cardsList } from "../../../utils/index";
import { useWindowSize } from "../../../hooks";

import styles from "./Goals.module.scss";

const Goals = () => {
  const { isMobile } = useWindowSize();
  const [activeIndex, setActiveIndex] = useState(0);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const handlerClick = (id) => {
    if (isMobile) {
      setActiveIndex(id);
      setIsOpenModal(true);
    }
  };

  const renderItems = cardsList.map(({ id, name, info, src }) => (
    <div key={id} className={styles.wrapper__flip_card}>
      <div className={styles.wrapper__flip_card__inner}>
        <div
          role='button'
          onClick={() => handlerClick(id - 1)}
          className={styles.wrapper__flip_card__front}>
          <img
            alt='gifCard'
            className={styles.wrapper__flip_card__bg}
            src={src}
          />
          <Text className={styles.wrapper__flip_card__title}>{name}</Text>
        </div>
        <div className={styles.wrapper__flip_card__back}>
          <Text className={styles.wrapper__flip_card__info}>{info}</Text>
        </div>
      </div>
    </div>
  ));

  return (
    <section className={`${styles.height_response} section`}>
      <div className={`container ${styles.wrapper}`}>
        <Heading className={styles.wrapper__title} isTitle>
          Our Goals
        </Heading>
        <div className={styles.wrapper__content}>{renderItems}</div>
      </div>
      <Modal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        data={cardsList[activeIndex]?.info}
      />
    </section>
  );
};

export default Goals;
