import { FC } from "react";
import { VideoType } from "./type";

const Video:FC<VideoType> = ({src, poster, className}) => (
    <div className={className}>
      <video autoPlay loop muted poster={poster} >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );

export default Video;
