const partnersItems = [
  {
    id: 1,
    src: '/img/images/partners/pn1.png',
  },
  {
    id: 2,
    src: '/img/images/partners/pn9.jpg',
  },
  {
    id: 3,
    src: '/img/images/partners/pn2.png',
  },
  {
    id: 4,
    src: '/img/images/partners/pn4.png',
  },
  {
    id: 5,
    src: '/img/images/partners/pn5.png',
  },
  {
    id: 6,
    src: '/img/images/partners/pn6.png',
  },
  {
    id: 7,
    src: '/img/images/partners/pn7.png',
  },
  {
    id: 8,
    src: '/img/images/partners/pn8.png',
  },
  {
    id: 9,
    src: '/img/images/partners/pn3.png',
  },
  {
    id: 10,
    src: '/img/images/partners/pn10.png',
  },
  {
    id: 11,
    src: '/img/images/partners/pn11.png',
  },
];

export default partnersItems;
