import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../../common/SvgIcon";
import { Button } from "../../../common/Button";
import { ContentBlockProps } from "../types";
import { Fade } from "react-awesome-reveal";
import {
  RightBlockContainer,
  Content,
  ContentWrapper,
  ListWrapper,
  ListContent,
} from "./styles";

const RightBlock = ({
  title,
  content,
  button,
  icon,
  t,
  id,
}: ContentBlockProps) => {
  return (
    <RightBlockContainer>
      <Fade direction='right'>
        <Row justify='space-between' align='middle' id={id}>
          {icon ? (
            <Col lg={11} md={11} sm={11} xs={24}>
              <ContentWrapper>
                <h6>{t(title)}</h6>
                {Array.isArray(content) ? (
                  content.map((el) => (
                    <Content key={`right-content ${el}`}>✔ {t(el)}</Content>
                  ))
                ) : (
                  <Content>{t(content)}</Content>
                )}
              </ContentWrapper>
            </Col>
          ) : (
            <>
              <h6>{t(title)}</h6>
              <ListWrapper>
                {Array.isArray(content) &&
                  content.map((el) => (
                    <ListContent
                      key={`Left-content ${el}`}
                      style={{ fontSize: "18px" }}>
                      ✔ {t(el)}
                    </ListContent>
                  ))}
              </ListWrapper>
            </>
          )}
          {icon && (
            <Col lg={11} md={11} sm={12} xs={24}>
              <SvgIcon src={icon} width='100%' height='100%' />
            </Col>
          )}
        </Row>
      </Fade>
    </RightBlockContainer>
  );
};

export default withTranslation()(RightBlock);
