const Close = ({ ...rest }) => (
  <svg
    width='50'
    height='50'
    viewBox='0 0 50 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}>
    <path
      d='M17.8313 33.2605L33.4526 17.6391C33.772 17.3197 33.7936 16.8229 33.5009 16.5302C33.2081 16.2375 32.7113 16.2591 32.392 16.5785L16.7706 32.1998C16.4512 32.5192 16.2376 33.2081 16.5303 33.5008C16.823 33.7935 17.5119 33.5798 17.8313 33.2605Z'
      fill='#1f1f1f'
    />
    <path
      d='M33.5008 32.4402L17.5909 16.5303C17.2981 16.2376 16.8229 16.2376 16.5302 16.5303C16.2375 16.823 16.2375 17.2982 16.5302 17.591L32.4402 33.5009C32.7329 33.7936 33.2081 33.7936 33.5008 33.5009C33.7935 33.2082 33.7935 32.733 33.5008 32.4402Z'
      fill='#1f1f1f'
    />
  </svg>
);

export default Close;
